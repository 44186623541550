import { useEffect, useRef } from "react";
import { useRouter } from "next/compat/router";

import { useFundraiserContext } from "@fwa/src/contexts/FundraiserContext";
import { useTracking } from "@fwa/src/hooks/useTracking";
import { useBrandContext } from "@fwa/src/contexts/BrandContext";

// listens for page changes and updates GTM with a page_loaded event
export const TrackingRouteListener = () => {
  const [fundraiserState] = useFundraiserContext();
  const [brandName] = useBrandContext();
  const isLoggedInStatusKnown =
    fundraiserState.loggedInStatus &&
    fundraiserState.loggedInStatus !== "unknown";
  const { trackEventGtm } = useTracking();
  const router = useRouter();
  const route = router?.route ?? "";
  const oldRoute = useRef<string>("init");

  useEffect(() => {
    if (
      route !== oldRoute.current &&
      fundraiserState &&
      isLoggedInStatusKnown &&
      brandName
    ) {
      oldRoute.current = route;
      trackEventGtm({
        event: "page_loaded",
        brand: brandName,
        loggedInUserId: fundraiserState.fundraiser?.uniqueId,
      });
    }
  }, [isLoggedInStatusKnown, fundraiserState, route, trackEventGtm, brandName]);

  return null;
};

export default TrackingRouteListener;
