import React, {
  useState,
  createContext,
  useContext,
  type Dispatch,
  type SetStateAction,
  type ReactNode,
  useMemo,
} from "react";

import {
  type Client,
  type EventTags,
  type OptimizelyUserContext,
  type UserAttributes,
} from "@optimizely/optimizely-sdk";

export type OptimizelyContextStateType = {
  optimizelyClient: Client | null;
  isServerSide: boolean;
  timeout: number | undefined;
  optimizelyUserContext: OptimizelyUserContext | null;
};

export type OptimizelyContextProviderType = [
  OptimizelyContextStateType,
  Dispatch<SetStateAction<OptimizelyContextStateType>>,
];

const defaultState: OptimizelyContextStateType = {
  optimizelyClient: null,
  optimizelyUserContext: null,
  isServerSide: false,
  timeout: 0,
};

export const OptimizelyContext = createContext<OptimizelyContextProviderType>([
  { ...defaultState },
  () => {
    // placeholder
  },
]);

export const useOptimizelyContext = () => {
  const [optimizelyContext, setOptimizelyContext] =
    useContext(OptimizelyContext);
  const { optimizelyClient, optimizelyUserContext } = optimizelyContext;

  // helper function to track events using optimizely user context information
  const trackEventOptimizely = (trackEventProps: {
    eventKey: string;
    overrideUserId?: string;
    overrideAttributes?: UserAttributes;
    eventTags?: EventTags;
  }) => {
    if (!optimizelyClient) return; // todo track error
    const currentUser: OptimizelyUserContext | null | undefined =
      trackEventProps.overrideUserId
        ? optimizelyClient?.createUserContext(
            trackEventProps.overrideUserId,
            trackEventProps.overrideAttributes,
          )
        : optimizelyUserContext;
    if (!currentUser) return; // todo track error
    optimizelyClient.track(
      trackEventProps.eventKey,
      currentUser.getUserId(),
      currentUser.getAttributes(),
      trackEventProps.eventTags,
    );
  };

  // helper function to set optimizely user context attributes and update GTM with new decision data
  const setOptimizelyUserAttributes = (
    UserAttributesObject: UserAttributes,
  ) => {
    if (!optimizelyClient || !optimizelyUserContext) return; // todo track error
    Object.keys(UserAttributesObject).forEach(
      (key: keyof typeof UserAttributesObject) => {
        optimizelyUserContext.setAttribute(
          `${key}`,
          UserAttributesObject[`${key}`],
        );
      },
    );
  };

  return {
    optimizelyContext,
    setOptimizelyContext,
    setOptimizelyUserAttributes,
    trackEventOptimizely,
  };
};

type Props = {
  children: ReactNode;
};

export const OptimizelyContextProvider = ({ children }: Props) => {
  const [state, setState] = useState({
    ...defaultState,
  });

  const value = useMemo(
    () => [state, setState],
    [state],
  ) as OptimizelyContextProviderType;

  return (
    <OptimizelyContext.Provider value={value}>
      {children}
    </OptimizelyContext.Provider>
  );
};
