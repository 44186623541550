import { useEffect } from "react";
import { createInstance } from "@optimizely/optimizely-sdk";
import { useOptimizelyContext } from "@fwa/src/contexts/OptimizelyContext";
import { useLocalStorage } from "@fwa/src/hooks/useLocalStorage";
import { useLayoutEffectBrowser } from "@fwa/src/hooks/useLayoutEffectBrowser";

function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime(); // Timestamp
  let d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; // Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = Math.random() * 16; // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export type OptimizelyLoaderProps = {
  sdkKey: string;
  onError?: (err: Error) => void;
};

/**
 * OptimizelyLoader initialises optimizely and sets user details
 */
export const OptimizelyLoader = ({
  sdkKey = "",
  onError,
}: OptimizelyLoaderProps) => {
  const { optimizelyContext, setOptimizelyContext } = useOptimizelyContext();
  // "logged_out" is default effectively user_id unset
  const { value: optimizelyId, setValue: setOptimizelyId } = useLocalStorage(
    "optimizely",
    "logged_out",
  );

  // init optimizely if we have a sdk key
  useEffect(() => {
    if (
      !sdkKey.length ||
      !optimizelyContext ||
      !setOptimizelyContext ||
      optimizelyContext.optimizelyClient
    )
      return;

    const client = createInstance({
      sdkKey,
      datafileOptions: { autoUpdate: true, updateInterval: 300000 },
      eventBatchSize: 20,
      eventFlushInterval: 1000,
    });
    if (client) {
      client
        .onReady()
        .then(() => {
          setOptimizelyContext({
            ...optimizelyContext,
            optimizelyClient: client,
          });
          return undefined;
        })
        .catch((err: Error) => {
          if (onError) onError(err);
        });
    }
  }, [
    sdkKey,
    optimizelyContext,
    setOptimizelyContext,
    onError,
    optimizelyContext.optimizelyClient,
  ]);

  // wait till we have access to browser to see if there is a stored optimizely id
  // if so use it, if not create one and use that
  useLayoutEffectBrowser(() => {
    if (optimizelyId === "logged_out") {
      const randomId = generateUUID();
      setOptimizelyId(randomId);
    }
  }, [optimizelyId]);

  // once we get/set an optimizely id and we have an optimizely client
  // create a user context if one doesn't exist
  useEffect(() => {
    if (
      !sdkKey.length ||
      !optimizelyContext ||
      !optimizelyContext.optimizelyClient ||
      !optimizelyId ||
      !setOptimizelyContext ||
      optimizelyId === "logged_out" ||
      optimizelyContext.optimizelyUserContext
    )
      return;

    const userContext = optimizelyContext.optimizelyClient.createUserContext(
      optimizelyId,
      { user_id: optimizelyId },
    );
    setOptimizelyContext({
      ...optimizelyContext,
      optimizelyUserContext: userContext,
    });
  }, [
    sdkKey.length,
    optimizelyContext,
    optimizelyContext.optimizelyClient,
    optimizelyId,
    setOptimizelyContext,
    optimizelyContext.optimizelyUserContext,
  ]);

  return null;
};

export default OptimizelyLoader;
