import { useRouter } from "next/router";

import { trackDataDogError } from "@fwa/src/services/dataDog";
import { sendGTMTrackingEvent } from "@fwa/src/services/gtm";
import { useFundraiserContext } from "@fwa/src/contexts/FundraiserContext";
import { useBrandContext } from "../contexts/BrandContext";

const routeToGTMPageType: Record<string, string> = {
  "/donate/[slug]/confirmation": "donation_confirmation",
  "/donate/[slug]/details": "donation_donation_details",
  "/fundraiser/dashboard": "dashboard",
  "/fundraiser/settings": "account_settings",
  "/page/[...slug]": "fundraiser_page",
  "/partner/[slug]": "partner_page",
  "/payment/failed": "payment_failed",
  "/team/[slug]": "team_page",
  "/team/[slug]/join": "team_page_join",
  "/team/[slug]/leave": "team_page_leave",
  "/team/[slug]/members": "team_page_members",
  "/team/[slug]/members/edit": "team_page_members_edit",
  "/unite/[slug]": "superteam_page",
  "/unite/[slug]/join": "superteam_page_join",
  "/unite/[slug]/pages": "superteam_page_fundraising_pages",
  "/unite/[slug]/teams": "superteam_page_team_pages",
  "/createpage": "create_page",
  "/signup": "signup",
  "/team-create": "team_create",
  "/team-search": "team_search",
  "/teamsignup": "team_signup",
  "/": "homepage",
};

const UNTRACKED_ERRORS = ["URL not unique"];

export const useTracking = () => {
  const router = useRouter();
  const { route: routerRoute } = router;
  const route = routerRoute
    ? routeToGTMPageType[routerRoute] || routerRoute
    : "unknown";

  const [fundraiserState] = useFundraiserContext();
  const { fundraiser } = fundraiserState;
  const [brandName] = useBrandContext();

  const trackEventGtm = (eventData?: {
    event: string;
    [key: string]: string | number | boolean | object | null | undefined;
  }) => {
    const newEventData = {
      pageType: route,
      fundraiserId: fundraiser?.uniqueId,
      brand: brandName,
      ...eventData,
    };
    sendGTMTrackingEvent(newEventData);
  };

  const trackError = (err: Error, extraData?: object) => {
    if (err.message && UNTRACKED_ERRORS.includes(err.message)) return;

    const newEventData = {
      ...extraData,
      route,
      brandName,
      fundraiserId: fundraiser?.uniqueId,
    };
    trackDataDogError(err, newEventData);
  };

  return { trackEventGtm, trackError };
};

// export default useTracking;
