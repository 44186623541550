import { useEffect } from "react";
import { setLogger } from "@optimizely/optimizely-sdk";

import { useFundraiserContext } from "@fwa/src/contexts/FundraiserContext";
import { useOptimizelyContext } from "@fwa/src/contexts/OptimizelyContext";
import { useTracking } from "@fwa/src/hooks/useTracking";

import { OptimizelyLoader } from "@fwa/src/components/OptimizelyLoader";

const OPTIMIZELY_SDK_KEY = process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY || "";
const ENV_NAME = process.env.NEXT_PUBLIC_ENV_NAME || "";

/**
 * OptimizelyFundraiserLoader set attributes on optimizely user context based on fundraiser state
 */
export const OptimizelyFundRaiserLoader = () => {
  const { trackError } = useTracking();
  const [fundraiserState] = useFundraiserContext();
  const { fundraiser } = fundraiserState;
  const { optimizelyContext, setOptimizelyUserAttributes } =
    useOptimizelyContext();

  if (ENV_NAME === "production") {
    setLogger(null);
  }
  // if fundraiser updates then we update the user context
  useEffect(() => {
    if (
      !optimizelyContext.optimizelyClient ||
      !optimizelyContext.optimizelyUserContext ||
      !fundraiserState ||
      !setOptimizelyUserAttributes ||
      fundraiserState.loggedInStatus === "unknown"
    )
      return;

    if (fundraiserState.loggedInStatus === "loggedIn" && fundraiser) {
      setOptimizelyUserAttributes({
        is_logged_in: true,
        fundraiser_id: fundraiser.uniqueId,
      });
    } else if (fundraiserState.loggedInStatus === "loggedOut") {
      setOptimizelyUserAttributes({ is_logged_in: false, fundraiser_id: null });
    }
  }, [
    optimizelyContext.optimizelyClient,
    optimizelyContext.optimizelyUserContext,
    fundraiserState,
    setOptimizelyUserAttributes,

    fundraiserState.loggedInStatus,
    fundraiser,
  ]);

  return (
    <OptimizelyLoader
      sdkKey={OPTIMIZELY_SDK_KEY}
      onError={(err: Error) => {
        trackError(err, {
          component: "OptimizelyLoader",
          function: "setOptimizelyContext",
        });
      }}
    />
  );
};

export default OptimizelyFundRaiserLoader;
