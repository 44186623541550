import { useEffect, type DependencyList } from "react";
import { isBrowser } from "@fwa/src/utils/browserUtils";

export const useKey = (
  callback: (event: KeyboardEvent) => void,
  options: {
    detectKeys?: string[];
    keyevent?: "keydown" | "keyup" | "keypress";
  },
  dependencies?: DependencyList,
): void => {
  const { detectKeys = [], keyevent } = options;
  const depsToString = JSON.stringify(dependencies);

  useEffect(() => {
    if (!isBrowser || !callback || !detectKeys.length) {
      return undefined;
    }

    const handleEvent = (event: KeyboardEvent) => {
      if (detectKeys.includes(event.key) || detectKeys.length === 0) {
        callback(event);
      }
    };
    window.document.addEventListener(keyevent || "keydown", handleEvent);
    return () => {
      window.document.removeEventListener(keyevent || "keydown", handleEvent);
    };
  }, [callback, depsToString, detectKeys, keyevent]);
};

export default useKey;
