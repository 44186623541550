import { useEffect, useState, memo, type ReactNode } from "react";
import { Modal, Heading, Text, Button, Box } from "@cruk/cruk-react-components";
import { useRouter } from "next/router";

import { useTracking } from "@fwa/src/hooks/useTracking";
import { useFundraiserContext } from "@fwa/src/contexts/FundraiserContext";
import {
  refreshToken,
  getToken,
  logOut,
  shouldRefresh,
} from "@fwa/src/services/auth";
import { getLoggedInFundraiser } from "@fwa/src/services/fundraiserService";
import usePrevious from "@fwa/src/hooks/usePrevious";
import { RowCenter } from "@fwa/src/components/styles";
import { type FundraiserType } from "@fwa/src/types";
import { logger } from "@fwa/src/services/logger";

// Login refresh works by periodically looking for the auth code in local storage and running
// two requests:
// 1) to get the auth code from the server from the auth code
// 2) Is to register the returned auth code to confirm a log in

type Props = {
  children: ReactNode;
};

export const LoginWrapper = ({ children }: Props) => {
  const { trackError, trackEventGtm } = useTracking();
  const [fundraiserState, setFundraiserState] = useFundraiserContext();
  const [refreshCheckCount, setRefreshCheckCount] = useState(0);
  const router = useRouter();
  const { pathname } = router || {};
  const prevLoggedInStatus = usePrevious(fundraiserState.loggedInStatus);
  const oauthUserNoFundraiser =
    fundraiserState.loggedInStatus === "loggedIn" &&
    !fundraiserState.fundraiser;
  const isSigningUp =
    pathname.startsWith("/signup") ||
    pathname.startsWith("/auth") ||
    pathname.startsWith("/createpage");
  const interuptedSignUp = oauthUserNoFundraiser && !isSigningUp;

  const setLoggedOutState = () => {
    setFundraiserState({
      ...fundraiserState,
      fundraiser: null,
      loggedInStatus: "loggedOut",
    });
  };

  const refresh = (): Promise<void | undefined> =>
    refreshToken()
      .then(() => {
        logger.info("token refreshed");
        return getLoggedInFundraiser();
      })
      .then((res) => res as FundraiserType)
      .then((userData) => {
        // Only request fundraiser info if we don't have it in state
        if (!fundraiserState.fundraiser) {
          setFundraiserState({
            ...fundraiserState,
            fundraiser: userData,
            loggedInStatus: "loggedIn",
          });
        }
        return undefined;
      })
      .catch((err) => {
        trackError(err as Error, { component: "LoginWrapper" });
        console.error("failed to refresh token and get fundraiser data");
        setLoggedOutState();
      });

  // If logging out on fundraiser/** redirect to home page.
  // This should happen when the user clicks on log out but this is just incase refresh fails
  useEffect(() => {
    if (
      prevLoggedInStatus === "loggedIn" &&
      fundraiserState.loggedInStatus === "loggedOut" &&
      (router.pathname.startsWith("/fundraiser/") ||
        router.pathname.startsWith("/signup/") ||
        router.pathname.startsWith("/createpage/"))
    ) {
      router.push({ pathname: "/" }).catch((err) => {
        trackError(err as Error, { component: "LoginWrapper" });
      });
    }
  }, [fundraiserState.loggedInStatus]);

  // /////////////////////////////
  // ////// TOKEN REFRESH  ///////
  // /////////////////////////////
  useEffect(() => {
    // Don't need to poll for token refresh if we are not logged in
    if (!getToken()) return;

    const timer = setTimeout(() => {
      if (shouldRefresh()) {
        refresh().catch((err) => {
          trackError(err as Error, { component: "LoginWrapper" });
        });
      }
      setRefreshCheckCount(refreshCheckCount + 1);
    }, 60000);
    return () => clearTimeout(timer);
    // we need fundraiser state as a dep so we can run again after log in
  }, [refreshCheckCount, setRefreshCheckCount, fundraiserState]);

  // /////////////////////////////////////////////////////
  // ////// CHECK IF ALREADY LOGGED IN WHEN APP OPENS ////
  // ////////////////////////////////////////////////////

  const doLogOut = () => {
    logOut().catch((error) => {
      trackError(error as Error, { component: "LoginWrapper" });
    });
  };

  useEffect(() => {
    if (!router.isReady) return;
    const { code } = router.query;
    if (getToken()) {
      // If we open the app and the auth code
      logger.log("already have authenticated token");
      getLoggedInFundraiser()
        .then((res) => res as FundraiserType)
        .then((userData: FundraiserType) => {
          setFundraiserState({
            ...fundraiserState,
            fundraiser: userData,
            loggedInStatus: "loggedIn",
          });
          trackEventGtm({
            event: "login",
            loggedInUserId: userData?.uniqueId,
          });
          return undefined;
        })
        .catch((err) => {
          trackError(err as Error, { component: "LoginWrapper" });
          logger.error("auth code failed get fundraiser data");
          setLoggedOutState();
          doLogOut();
        });
    } else {
      // if we have code we might be in the process of logging in so don't log out
      if (code) return;
      // /////////////////////////////
      // ////// LOGGED OUT ///////////
      // /////////////////////////////
      logger.info("no login codes");
      setLoggedOutState();
    }
  }, [router.isReady]);

  return (
    <>
      {children}
      {interuptedSignUp && (
        <Modal
          modalName="Continue SignUp"
          closeFunction={() => {
            // placeholder no op
          }}
          top="4rem"
        >
          <div data-component="continue-sign-up">
            <Heading>It looks like we lost you</Heading>
            <Text>
              {`Let's take you back to where you were so you can finish signing up
            and start your fundraising journey`}
            </Text>
            <Box marginBottom="s">
              <RowCenter>
                <Button
                  appearance="primary"
                  onClick={() => {
                    router.push({ pathname: `/signup` }).catch((err) => {
                      trackError(err as Error, { component: "LoginWrapper" });
                    });
                  }}
                  data-cta-type="sign-up"
                >
                  I want to sign up
                </Button>
              </RowCenter>
            </Box>
            <Box>
              <RowCenter>
                <Button
                  appearance="secondary"
                  onClick={() => {
                    logOut()
                      .then(() => {
                        setFundraiserState({
                          ...fundraiserState,
                          loggedInStatus: "loggedOut",
                          fundraiser: null,
                        });
                        return undefined;
                      })
                      .catch((err) => {
                        trackError(err as Error, { component: "LoginWrapper" });
                      });
                  }}
                  data-cta-type="cancel-sign-up"
                >
                  I want to cancel
                </Button>
              </RowCenter>
            </Box>
          </div>
        </Modal>
      )}
    </>
  );
};

export default memo(LoginWrapper);
